<template>
  <div id="area">
    <div id="top" :class="{ open: pickerOpen }">
      <img id="x" src="../assets/redX.svg" @click="pickerOpen = false" />
      <ItemPicker @add="addNewItem" class="item-picker"></ItemPicker>
    </div>
    <div id="search" @click="pickerOpen = !pickerOpen">
      <img src="../assets/search.svg" />
    </div>
    <div id="bottom">
      <div id="input-cards">
        <ItemCard v-for="(skin, index) in skins" :key="'A' + index" :name="skin.name" :float="skin.float"
          :statTrak="skin.statTrak" removable @remove="removeItem(index)"></ItemCard>
        <div class="placeholder-card" v-for="n in 10 - skins.length" :key="n"></div>
        <div v-if="skins.length > 0" id="clear">
          <div @click="clear">Clear all</div>
        </div>
      </div>
      <div v-if="tradeup" id="results">
        <div id="output-cards">
          <div id="items-container">
            <ItemCard v-for="item in tradeup.results" :key="'B' + item.name" :name="item.name" :float="item.float"
              :statTrak="skins[0].statTrak" :percentage="item.chance" isFullName></ItemCard>
          </div>
        </div>
      </div>
      <div id="tradeup-stats" v-if="tradeup" :style="{
        '--profit-color':
          Number.parseInt(tradeup.profit.percent) > 0 ? '#4beb5b' : '#DF5151',
      }">
        <div id="percent">{{ tradeup.profit.percent }}</div>
        <div id="profit">{{ currency(tradeup.profit.total) }}</div>
        <div class="statline">
          <span>Cost:</span>
          <span>{{ currency(tradeup.cost) }}</span>
        </div>
        <div class="statline">
          <span>Value: </span>
          <span>{{ currency(tradeup.averageValue) }}</span>
        </div>
        <float-meter class="float" :max-float="+tradeup.maxFloat" />
        <div class="statline">
          <span>MAX FLOAT: </span>
          <span>{{ tradeup.maxFloat }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemPicker from "./ItemPicker.vue";
import ItemCard from "./ItemCard.vue";
import FloatMeter from "./FloatMeter.vue";
import {
  parseSingleItem,
  itemArrayToUtid,
  getInputSkins,
} from "../tradeUps.js";
import { applyCurrency } from "../util";

export default {
  name: "Calculator",
  components: {
    ItemPicker,
    ItemCard,
    FloatMeter,
  },
  data() {
    return {
      skins: [],
      tradeup: null,
      prices: null,
      utid: "",
      pickerOpen: false,
    };
  },
  methods: {
    calculateUtid() {
      this.utid = itemArrayToUtid(this.skins, this.$store.state.weaponsClient);
    },
    removeItem(i) {
      this.skins.splice(i, 1);
      this.tradeup = null;
    },
    addNewItem(skin) {
      if (
        this.skins.length === 0 ||
        (this.skins[0].rarity === skin.rarity &&
          this.skins[0].statTrak === skin.statTrak)
      ) {
        if (this.skins.length === 9) {
          this.skins.push(skin);
          this.calculateUtid();
          this.calculate();
          this.pickerOpen = false;
        } else if (this.skins.length < 10) {
          this.skins.push(skin);
        }
      } else {
        alert("all skins need to be the same rarity and type!");
      }
    },
    currency(value) {
      return applyCurrency(
        value,
        this.$store.state.currency,
        this.$store.state.currencyExchangeRate
      );
    },
    clear() {
      this.skins = [];
      this.tradeup = null;
    },
    calculate() {
      fetch(`${process.env.VUE_APP_API_DOMAIN}/calculate/${this.utid}`)
        .then((r) => r.json())
        .then((data) => {
          if (data.success) {
            this.tradeup = parseSingleItem(
              data.tradeUp,
              this.$store.state.weaponsClient
            );
            if (this.skins.length === 0) {
              this.skins = getInputSkins(
                data.tradeUp,
                this.$store.state.weaponsClient
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.show("Something went wrong...");
        });
    },
  },
  created() {
    this.$store.dispatch("fetchPrices");
    this.$store.dispatch("fetchWeaponsClient");
  },
  mounted() {
    if (this.$route.query.utid) {
      this.utid = this.$route.query.utid;
      this.calculate();
    }
  },
};
</script>

<style scoped lang="scss">
#area {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

#top {
  max-width: 1120px;
  width: 70%;

  #x {
    display: none;
  }

  .item-picker {
    width: 100%;
    background: rgba(238, 250, 255, 0.2);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 20px 160px;
    box-sizing: border-box;
    position: relative;

    &::before {
      content: "Item finder";
      position: absolute;
      bottom: calc(100% + 20px);
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
      left: 0;
    }
  }
}

#bottom {
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  #input-cards {
    position: relative;
    display: grid;
    grid-template-columns: 120px 120px 120px 120px 120px;
    grid-template-rows: 120px 120px 20px;
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    grid-template-areas:
      ". . . . ."
      ". . . . ."
      "clear clear clear clear clear";
    background: rgba(238, 250, 255, 0.2);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 15px;

    #item-card:hover {
      cursor: pointer;
    }

    &::before {
      content: "Input Skins";
      position: absolute;
      bottom: calc(100% + 20px);
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
      left: 0;
    }

    #clear {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #c1eeff;
      text-decoration: underline;
      grid-area: clear;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      div:hover {
        cursor: pointer;
      }
    }
  }

  #output-cards {
    position: relative;

    background: rgba(238, 250, 255, 0.2);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;

    width: 100%;
    max-width: 600px;
    height: 320px;
    padding-right: 2px;

    #items-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, 120px);
      grid-template-rows: repeat(auto-fill, 120px);
      grid-row-gap: 15px;
      grid-column-gap: 15px;

      max-width: 600px;
      height: 100%;
      padding: 15px;
      box-sizing: border-box;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #012a36;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #012a36;
      }
    }

    &::before {
      content: "Output Skins";
      position: absolute;
      bottom: calc(100% + 20px);
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
      left: 0;
    }
  }

  #tradeup-stats {
    position: relative;
    min-height: 265px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: rgba(238, 250, 255, 0.2);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 40px 20px;
    box-sizing: border-box;

    #percent,
    #profit {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      color: var(--profit-color);
    }

    #profit {
      margin-bottom: 20px;
    }

    .float {
      margin-top: 10px;
    }

    .statline {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;

      &:last-of-type {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &::before {
      content: "Results";
      position: absolute;
      bottom: calc(100% + 20px);
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
      left: 0;
    }
  }

  #results {
    display: flex;
    flex-wrap: wrap;
    width: 150px;
    flex-grow: 1;
    margin: 0 15px;
  }

  .placeholder-card {
    width: 120px;
    height: 120px;
    background: #b9cfd8;
    border-radius: 5px;
  }
}

#search {
  display: none;
}

@media only screen and (max-width: 1365px) {
  #search {
    display: block;
    right: 0;
    bottom: 10vh;
    position: fixed;
    background: rgba(235, 75, 75, 0.7);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;

    img {
      width: 20px;
      height: 20px;
    }
  }

  #top {
    width: 100%;
    display: none;
    position: fixed;
    top: 0;
    min-height: 100vh;
    background: rgba(1, 42, 54, 0.95);
    z-index: 1000000000;

    #x {
      display: block;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 20px;
      height: 20px;
      padding: 10px;
      z-index: 100;
    }

    &.open {
      display: block;
    }

    .item-picker {
      height: 100vh;
      padding: 60px 10px 0 10px;
    }
  }

  #bottom {
    flex-direction: column;

    #input-cards {
      grid-template-columns: repeat(auto-fill, 120px);
      grid-template-rows: repeat(auto-fill, 120px);
      grid-row-gap: 15px;
      grid-column-gap: 15px;
      grid-template-areas:
        ". ."
        ". ."
        ". ."
        ". ."
        ". ."
        "clear clear";
      justify-content: center;
    }

    #output-cards {
      margin-top: 100px;
      height: auto;

      #items-container {
        justify-content: center;
      }
    }

    #tradeup-stats {
      margin-top: 100px;
      width: 100%;
    }
  }

  #results {
    width: 100% !important;
    flex-grow: 0 !important;
    margin: 0 !important;
  }

  #output-cards {
    max-width: 100% !important;

    #items-container {
      max-width: 100% !important;
    }
  }
}
</style>
